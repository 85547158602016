import styled from 'styled-components/macro';

const ReferenceLayout = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 16px 4px 20px;

    color: var(--nav-color);
    font-size: var(--nav-font-size);
    text-decoration: none;

    cursor: pointer;

    & > svg {
        font-size: 1.2em;
    }
`;

export default ReferenceLayout;
