import createEventEmitter from 'utils/create-event-emitter';

/**
 * Register what expressions user fires
 */
const expressionEmitter = createEventEmitter();

export const subscribe = expressionEmitter.subscribe;

/**
 * todo need to think about how to handle this event accurately
 */
export const emitExpression = exp => expressionEmitter.emit(exp);
