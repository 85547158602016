import React from 'react';
import { StyledSettingPanelItem } from './StyledSettings';
import NumberInputItem from 'components/settings/inputs/NumberInputItem';
import CheckInputItem from 'components/settings/inputs/CheckInputItem';

const SettingItem = ({ setting, updateSettingValue }) => {
    const getItem = (setting, updateSettingValue) => {
        if (setting.settingType === 'number')
            return <NumberInputItem setting={setting} updateSettingValue={updateSettingValue} />;

        if (setting.settingType === 'checkbox')
            return <CheckInputItem setting={setting} updateSettingValue={updateSettingValue} />;
    };

    return (
        <StyledSettingPanelItem key={setting.uuid}>
            <div>
                <label>{setting.name}</label>
                {getItem(setting, updateSettingValue)}
            </div>
            <p>{setting.hint}</p>
        </StyledSettingPanelItem>
    );
};

export default SettingItem;
