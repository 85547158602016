import { actionTypes, subscribe as appSubscribe } from 'emitters/app-emitter';
import { subscribe as expSubscribe } from 'emitters/expression-emitter';
import ReactGA from 'react-ga';
import throttle from 'lodash/throttle';

/**
 * Subscribes for events emitted by application
 */
export const subscribeForEvents = () => {
    subscribeForAppEvents();
    subscribeForExpressions();
};

export const subscribeForExpressions = () => {
    expSubscribe(
        throttle(exp => {
            ReactGA.event({
                category: 'Expression',
                action: 'eval',
                label: `exp: [${exp}]`
            });
        }, 500)
    );
};

const subscribeForAppEvents = () => {
    appSubscribe(({ type, name }) => {
        switch (type) {
            case actionTypes.SHARE: {
                ReactGA.event({
                    category: 'File',
                    action: 'Share',
                    label: 'name:' + name
                });
                break;
            }
            case actionTypes.FILE_DELETED: {
                ReactGA.event({
                    category: 'File',
                    action: 'Delete'
                });
                break;
            }
            case actionTypes.SHARED_FILE_OPENED: {
                ReactGA.event({
                    category: 'File',
                    action: 'Shared file opened',
                    label: 'name: ' + name
                });
                break;
            }
            case actionTypes.FILE_CREATED: {
                ReactGA.event({
                    category: 'File',
                    action: 'Create'
                });
                break;
            }
            case actionTypes.FOLDER_RENAMED: {
                ReactGA.event({
                    category: 'Folder',
                    action: 'Rename',
                    label: 'name: ' + name
                });
                break;
            }
            case actionTypes.FOLDER_DELETED: {
                ReactGA.event({
                    category: 'Folder',
                    action: 'Delete'
                });
                break;
            }
            case actionTypes.FOLDER_CREATED: {
                ReactGA.event({
                    category: 'Folder',
                    action: 'Create'
                });
                break;
            }
            default:
                return;
        }
    });
};
