import styled from 'styled-components/macro';
import { sm } from 'constants/device-widths';
import { COLUMN_MODE } from 'components/settings-context/view-modes';

export const StyledBricksNoteTitle = styled.div`
    display: flex;
    margin-left: 1.5rem;
    padding: 1rem 1rem 0 1rem;

    font-weight: bold;

    background: white;

    @media (max-width: ${sm}px) {
        min-height: 36px;
        margin-left: 0;
        padding: 0;
    }
`;

// noinspection CssUnknownProperty
export const StyledHeaderInput = styled.textarea`
    flex: 1 1 auto;
    padding: 0;

    border: none;

    color: var(--toolbar-color-activ);
    font-weight: 500;

    outline: none;

    resize: none;
    scrollbar-width: none;

    &::placeholder {
        color: rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const TextAreaWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;

    font-size: var(--title-font-size);

    @media (max-width: ${sm}px) {
        min-height: 20px;
        padding: ${({ viewMode }) => (viewMode === COLUMN_MODE ? '0.5rem 1.5rem 0.5rem 2.5rem' : '1rem 1rem 0 2.5rem')};

        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        font-size: 1rem;
    }
`;
