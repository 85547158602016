import { subscribeForEvents } from 'components/google-analytics/ga-events';

/**
 * start simple google analytics
 */
export const startGoogleAnalytics = () => {
    if (process.env.REACT_APP_GA_KEY) {
        import('react-ga').then(({ default: ReactGA }) => {
            ReactGA.initialize(process.env.REACT_APP_GA_KEY);
            ReactGA.pageview(window.location.pathname);

            subscribeForEvents();
        });
    }
};
