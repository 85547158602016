import React from 'react';
import { createSvgIcon } from 'components/svg-icons/create-svg-icon';

const RowModeIcon = createSvgIcon(
    <path
        fillRule="evenodd"
        d="M1 5C1 3.89543 1.89543 3 3 3H21C22.1046 3 23 3.89543 23 5V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5ZM21 5H3V19H21V5Z"
        clipRule="evenodd"
    />
);

export default RowModeIcon;
