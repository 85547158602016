import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
    StyledBricksNoteTitle,
    StyledHeaderInput,
    TextAreaWrapper
} from 'components/bricks-header/StyledBricksNoteTitle';
import { subscribe } from 'emitters/file-title-emitter';
import throttle from 'lodash/throttle';
import { focus } from 'emitters/app-emitter';

const fixTextareaHeight = textarea => {
    textarea.style.height = 0;
    textarea.style.height = textarea.scrollHeight + 'px';
};

const AutoHeightTextArea = props => {
    const textareaEl = useRef();

    useEffect(() => {
        fixTextareaHeight(textareaEl.current);
    }, []);

    const handleInput = useCallback(() => {
        fixTextareaHeight(textareaEl.current);
    }, []);

    const handleEnter = useCallback(event => {
        const enterIsPressed = event.key === 'Enter';
        if (enterIsPressed) {
            event.preventDefault();
            focus();
        }
    }, []);

    return <StyledHeaderInput {...props} ref={textareaEl} onInput={handleInput} onKeyDown={handleEnter} />;
};

const BricksNoteTitle = props => {
    const { uuid, defaultValue, renameFile } = props;
    const { viewMode } = props;

    const [filename, setFilename] = useState(defaultValue || '');

    const updateFileName = useCallback(
        throttle(({ name, uuid, renameFile }) => {
            renameFile({ name, uuid });
        }, 150),
        []
    );

    const handleValueChange = useCallback(
        ({ target }) => {
            const { value } = target;
            setFilename(value);
            updateFileName({ name: value, uuid, renameFile });
        },
        [renameFile, updateFileName, uuid]
    );
    useEffect(
        () =>
            subscribe(change => {
                change.uuid === uuid && setFilename(change.name);
            }),
        [uuid]
    );

    return (
        /* this need to wrap in its own styled component - get rid of div*/
        <StyledBricksNoteTitle>
            <TextAreaWrapper viewMode={viewMode}>
                <AutoHeightTextArea
                    value={filename}
                    onChange={handleValueChange}
                    aria-label="Note title"
                    placeholder="Type note title..."
                />
            </TextAreaWrapper>
        </StyledBricksNoteTitle>
    );
};

export default BricksNoteTitle;
