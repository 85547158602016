import React from 'react';
import { createSvgIcon } from 'components/svg-icons/create-svg-icon';

const ColumnModeIcon = createSvgIcon(
    <path
        fillRule="evenodd"
        d="M1 5c0-1.1.9-2 2-2h18a2 2 0 012 2v14a2 2 0 01-2 2H3a2 2 0 01-2-2V5zm13 0H3v14h11V5zm2 0v14h5V5h-5z"
        clipRule="evenodd"
    />
);

export default ColumnModeIcon;
