import styled from 'styled-components/macro';
import { sm } from 'constants/device-widths';
import { COLUMN_MODE } from 'components/settings-context/view-modes';

export const StyledBricksGoToNavHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: ${({ viewMode }) => (viewMode === COLUMN_MODE ? '1rem 11.5rem 0 2.5rem' : '1rem 1.5rem 0 2.5rem')};

    @media (max-width: ${sm}px) {
        & {
            z-index: 300;

            flex-direction: column;
            align-items: stretch;
            width: 100%;
            padding: 0;
        }
    }
`;

export const StyledToolbar = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 1.5em;

    color: rgba(0, 0, 0, 0.4);
    font-size: 0.875rem;

    & > * {
        margin-left: 1rem;

        font-size: 1.2em;
    }

    & > *:last-child {
        font-size: 1.4em;
    }

    @media (max-width: ${sm}px) {
        display: ${({ mobile }) => (mobile ? 'flex' : 'none')};

        font-size: 1.2rem;

        & > * {
            width: 2rem;
            height: 3rem;
            margin-left: 0.5rem;

            font-size: 0.9em;
        }

        & > *:last-child {
            margin-right: 0.25rem;

            font-size: 1em;
        }
    }
`;

export const GoToNavigationWrapper = styled.div`
    display: none;

    background-color: white;

    @media (max-width: ${sm}px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 48px;

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
`;

export const GoToNavigation = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    margin: 2px;
    padding: 0.5rem 0.5rem 0.5rem 0;

    border: none;

    color: var(--editor-main-color);
    font-size: 1rem;

    background: transparent;
    cursor: pointer;

    & > svg {
        font-size: 1.4em;
    }

    & > span {
        padding-right: 0.25rem;
    }
`;
