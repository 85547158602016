import React, { useCallback, useContext, useRef } from 'react';
import StyledBricks, { NotepadContainer, StyledBricksHeader } from 'components/bricks/StyledBricks';
import BricksGoToNavHeader from 'components/bricks-header/BricksGoToNavHeader';
import { focus } from 'emitters/app-emitter';
import FilesContext from 'components/files-context/FilesContext';
import StatefulNotepad from 'components/notepad/StatefulNotepad';
import { useSharedNotesReader } from 'components/bricks/shared-notes-hook';
import { SettingsContext } from 'components/settings-context/SettingsContext';
import { SETTINGS_MODE } from 'components/settings-context/view-modes';
import { StyledSettingsContainer } from 'components/settings/StyledSettings';
import SettingsContainer from 'components/settings/SettingsContainer';
import SettingHeader from 'components/settings/SettingsHeader';
import { CALCULATOR, EDITOR, UI_SETTINGS } from 'constants/setting-types';
import BricksNoteTitle from 'components/bricks-header/BricksNoteTitle';

const Bricks = ({ onShowNavigation }) => {
    const containerEl = useRef();
    const handleFocus = useCallback(({ target }) => target === containerEl.current && focus(), []);

    const { viewMode, toggleViewMode, settingsMode, settings } = useContext(SettingsContext);
    const { inited, addSharedFileAndSelect, selectedFile, renameFile, deleteFile } = useContext(FilesContext);

    const { uuid, name } = selectedFile || {};

    const headerProps = { viewMode, onShowNavigation, settingsMode, renameFile, deleteFile, toggleViewMode };

    useSharedNotesReader(inited, addSharedFileAndSelect);

    const notepadProps = {
        name,
        viewMode,
        settings
    };

    return (
        <StyledBricks ref={containerEl} onClick={handleFocus}>
            {settingsMode === SETTINGS_MODE ? (
                <>
                    <SettingHeader defaultValue={name} {...headerProps} />
                    <StyledSettingsContainer viewMode={viewMode}>
                        <SettingsContainer
                            title="Calculator settings"
                            typeFilter={setting => setting.type === CALCULATOR}
                        />
                        <SettingsContainer title="Editor settings" typeFilter={setting => setting.type === EDITOR} />
                        <SettingsContainer title="UI Settings" typeFilter={setting => setting.type === UI_SETTINGS} />
                    </StyledSettingsContainer>
                </>
            ) : (
                <>
                    <StyledBricksHeader viewMode={viewMode}>
                        <BricksGoToNavHeader uuid={uuid} defaultValue={name} {...headerProps} />
                        <BricksNoteTitle
                            key={uuid}
                            uuid={uuid}
                            defaultValue={name}
                            viewMode={viewMode}
                            {...headerProps}
                        />
                    </StyledBricksHeader>

                    <NotepadContainer viewMode={viewMode}>
                        <StatefulNotepad notepadProps={notepadProps} />
                    </NotepadContainer>
                </>
            )}
        </StyledBricks>
    );
};

export default Bricks;
