import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SettingsContext } from 'components/settings-context/SettingsContext';
import { COLUMN_MODE, NORMAL_MODE, ROW_MODE, SETTINGS_MODE } from 'components/settings-context/view-modes';
import * as BricksDb from 'db/db';
import { updateCollection, uuidEquals } from 'db/db-utils';

const SettingsProvider = ({ children }) => {
    const [viewMode, setViewMode] = useState(localStorage.viewMode || COLUMN_MODE);
    const [settingsMode, setSettingsMode] = useState(localStorage.settingsMode || NORMAL_MODE);
    const [settings, setSettings] = useState([]);
    const dbRef = useRef();
    const [inited, setInited] = useState(false);
    const settingsInitedRef = useRef(false);

    useEffect(() => {
        const db = BricksDb.get();
        dbRef.current = db;

        db.settings.toArray().then(settings => {
            setSettings(settings);
            if (settingsInitedRef.current) setInited(true);
        });
    }, []);

    const toggleViewMode = useCallback(() => {
        setViewMode(prevViewMode => (prevViewMode === COLUMN_MODE ? ROW_MODE : COLUMN_MODE));
    }, [setViewMode]);

    const openSettings = useCallback(() => {
        setSettingsMode(prevMode => (prevMode === NORMAL_MODE ? SETTINGS_MODE : NORMAL_MODE));
    }, []);

    useEffect(() => {
        localStorage.viewMode = viewMode;
    }, [viewMode]);

    const updateSettingValue = useCallback(({ uuid, value }) => {
        const db = dbRef.current;
        db.settings.update(uuid, { value: value }).then(() => {
            setSettings(prevSettings => updateCollection(prevSettings, uuidEquals(uuid), { value }));
        });
    }, []);

    const providedValue = useMemo(
        () => ({
            inited,
            viewMode, //todo rename this to more correct word: displayResultMode
            toggleViewMode,
            settings,
            updateSettingValue,
            settingsMode,
            openSettings
        }),
        [inited, viewMode, toggleViewMode, settings, updateSettingValue, settingsMode, openSettings]
    );

    return <SettingsContext.Provider value={providedValue}>{children}</SettingsContext.Provider>;
};

export default SettingsProvider;
