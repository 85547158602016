import React, { useCallback, useState } from 'react';
import { StyledFolderEditForm } from 'components/folder-edit/folder-edit-styles';
import SaveIcon from 'components/svg-icons/SaveIcon';

const FolderEditForm = ({ previousName, onRenameFolder }) => {
    const [nextName, setNextName] = useState(previousName);

    const handleFormSubmit = useCallback(
        event => {
            event.preventDefault();
            onRenameFolder(nextName);
        },
        [nextName, onRenameFolder]
    );

    const handleNameChange = useCallback(event => {
        setNextName(event.target.value);
    }, []);

    return (
        <StyledFolderEditForm onSubmit={handleFormSubmit}>
            <input type="text" value={nextName} onChange={handleNameChange} />
            <button type="submit">
                <SaveIcon />
            </button>
        </StyledFolderEditForm>
    );
};

export default FolderEditForm;
