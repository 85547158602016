import React, { useCallback } from 'react';
import {
    GoToNavigation,
    GoToNavigationWrapper,
    StyledBricksGoToNavHeader
} from 'components/bricks-header/StyledBricksGoToNavHeader';
import { share } from 'emitters/app-emitter';
import { showSnack } from 'emitters/snack-emitter';
import ChevronLeft from 'components/svg-icons/ChevronLeft';
import Toolbar from 'components/bricks-header/Toolbar';

/**
 * Controls navigation on mobile devices.
 * When user opens a file this nav gives him ability to go back to the main navigation
 */
const BricksGoToNavHeader = props => {
    const { uuid, deleteFile, onShowNavigation } = props;
    const { viewMode, toggleViewMode } = props;

    const handleShare = useCallback(name => share(name), []);

    const handleDeleteFile = useCallback(() => {
        showSnack('Are you sure?', { actions: ['ok', 'dismiss'] }).then(result => {
            result === 'ok' && deleteFile({ uuid });
        });
    }, [uuid, deleteFile]);

    const toolbarProps = {
        viewMode,
        onToggleViewMode: toggleViewMode,
        onDelete: handleDeleteFile,
        onShare: handleShare
    };

    return (
        <StyledBricksGoToNavHeader viewMode={viewMode}>
            <GoToNavigationWrapper>
                <GoToNavigation onClick={onShowNavigation}>
                    <ChevronLeft />
                    <span>Notes</span>
                </GoToNavigation>
                <Toolbar mobile {...toolbarProps} />
            </GoToNavigationWrapper>
            <Toolbar {...toolbarProps} />
        </StyledBricksGoToNavHeader>
    );
};

export default BricksGoToNavHeader;
