import { keymap } from 'prosemirror-keymap';
import { buildKeymap } from 'prosemirror-example-setup';
import calculationPlugin from 'components/notepad/plugins/calculation/calculation-plugin';
import lineNumberPlugin from 'components/notepad/plugins/line-number-plugin';
import highlightPlugin from 'components/notepad/plugins/highlight-plugin';
import idPlugin from 'components/notepad/plugins/id-plugin';
import { baseKeymap } from 'prosemirror-commands';
import { history } from 'prosemirror-history';
import { lineInputRulePlugin } from 'components/notepad/plugins/line-input-rule';
import { createChangesPlugin } from 'components/notepad/plugins/changes-plugin';
import { schema } from 'components/notepad/schema';
import reusedBadgeHighlightPlugin from 'components/notepad/plugins/reused-badge-highlight-plugin';

const buildPlugins = ({ uuid, onChange, settings }) => {
    return [
        keymap(buildKeymap(schema)),
        keymap(baseKeymap),
        calculationPlugin(settings),
        lineNumberPlugin,
        highlightPlugin,
        idPlugin,
        lineInputRulePlugin,
        history(),
        createChangesPlugin({ uuid, onChange }),
        reusedBadgeHighlightPlugin
    ];
};

export default buildPlugins;
