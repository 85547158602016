import React from 'react';
import IconButton from 'components/svg-icons/IconButton';
import { COLUMN_MODE } from 'components/settings-context/view-modes';
import RowModeIcon from 'components/svg-icons/RowModeIcon';
import ColumnModeIcon from 'components/svg-icons/ColumnModeIcon';
import ShareIcon from 'components/svg-icons/ShareIcon';
import FileMenuButton from 'components/file-tree/FileMenuButton';
import { StyledToolbar } from 'components/bricks-header/StyledBricksGoToNavHeader';

const Toolbar = ({ viewMode, mobile, onToggleViewMode, onShare, onDelete }) => {
    return (
        <StyledToolbar mobile={mobile}>
            <IconButton title="Toggle view mode" onClick={onToggleViewMode}>
                {viewMode === COLUMN_MODE ? <RowModeIcon /> : <ColumnModeIcon />}
            </IconButton>
            <IconButton title="Share" onClick={onShare}>
                <ShareIcon />
            </IconButton>
            <FileMenuButton onDelete={onDelete} />
        </StyledToolbar>
    );
};

export default Toolbar;
