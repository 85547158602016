import styled from 'styled-components/macro';

export const StyledFolderEditForm = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 10px 4px 20px;

    & > input {
        flex: 1 1 auto;
    }

    & > button {
        border: none;

        background-color: transparent;
        outline: none;
        cursor: pointer;
    }
`;
