import { createGlobalStyle } from 'styled-components/macro';
import { normalize } from 'polished';
import { sm } from 'constants/device-widths';

export const GlobalStyles = createGlobalStyle`
    body {
        font-family: Noto Sans, -apple-system, BlinkMacSystemFont, Segoe UI, sans-serif;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    ${normalize()}

    :root {

        /* backgrounds */
        --main-bg-color: #f6f6f6;
        --overlay-bg-color: #e5e5e5;
        --editor-bg-color: #fff;
        --editor-badge-bg-color: #f8f8f8;
        --editor-gutter-bg-color: #fafafa;

        /* fonts */
        --title-font-size: 1rem;
        --toolbar-font-size: 0.875rem;
        --toolbar-goto-font-size: 1.4rem;
        --nav-font-size: 0.875rem;
        --nav-title-font-size: 1.2rem;
        --nav-icon-font-size: 1rem;
        --editor-main-font-size: 0.875rem;
        --editor-line-number-font-size: 12px;

        /* font colors */
        --main-color: rgba(0, 0, 0, 0.4);
        --title-color: rgba(0, 0, 0, 0.4);
        --title-color-active: rgba(0, 0, 0, 0.4);
        --toolbar-color: rgba(0, 0, 0, 0.4);
        --toolbar-color-active: rgba(0, 0, 0, 0.8);
        --nav-color: rgba(0, 0, 0, 0.6);
        --nav-color-active: rgba(0, 0, 0, 0.4);
        --editor-main-color: rgba(0, 0, 0, 0.8);
        --editor-badge-color: rgba(0, 0, 0, 0.6);
        --editor-line-number-color: rgba(0, 0, 0, 0.4);
        --editor-number-color: #3e31ce;

        /* shadows */
        --material-box-shadow:  1px 0 6px rgba(0, 0, 0, 0.04), 1px 0 4px rgba(0, 0, 0, 0.12);

        /* border colors */
        --splitter-border-color: rgba(0, 0, 0, 0.05);
        --editor-exp-border-color: #b2b2b2;
        --editor-gutter-border-color: rgba(0, 0, 0, 0.12);

         --nav-expand-btn-size: 1.25rem;

    }

     @media (max-width: ${sm}px) {

       :root {
            --title-font-size: 1.2rem;
            --toolbar-font-size: 1.2rem;
            --nav-title-font-size: 1.6rem;
            --nav-font-size: 0.875rem;

            --nav-expand-btn-size: 1.6rem;
       }

    }
 `;

export default GlobalStyles;
