import React, { memo, useCallback, useContext, useState } from 'react';
import { showSnack } from 'emitters/snack-emitter';
import TransitionedFolderFiles from 'components/file-tree/TransitionedFolderFiles';
import FolderHeader from 'components/file-tree/FolderHeader';
import FolderEditContext from 'components/folder-edit/FolderEditContext';
import FolderEditForm from 'components/folder-edit/FolderEditForm';
import * as BricksDb from 'db/db';

const Folder = memo(props => {
    const [opened, setOpened] = useState(null);
    const { folder, deletable = false } = props;
    const { addFileAndSelect, selectFile, selectedFile, deleteFolder } = props;

    const { uuid, folderId } = selectedFile || {};
    const folderIsSelected = folderId === folder.uuid;

    const handleAddFile = useCallback(() => {
        addFileAndSelect({ folderId: folder.uuid });
    }, [addFileAndSelect, folder.uuid]);

    const handleDeleteFolder = useCallback(() => {
        const db = BricksDb.get();

        db.files
            .where('folderId')
            .equals(folder.uuid)
            .count()
            .then(folderSize => {
                if (folderSize === 0) {
                    deleteFolder({ uuid: folder.uuid });
                    return;
                }
                showSnack('This folder contains files, are you sure?', { actions: ['ok', 'dismiss'] }).then(result => {
                    result === 'ok' && deleteFolder({ uuid: folder.uuid });
                });
            });
    }, [deleteFolder, folder]);

    const toggleFiles = useCallback(() => {
        setOpened(prev => (prev === null ? !folderIsSelected : !prev));
    }, [folderIsSelected]);

    const expanded = opened === null ? folderIsSelected : opened;

    const { editedFolderId, selectFolder, commitChanges } = useContext(FolderEditContext);

    const handleEdit = useCallback(() => {
        selectFolder(folder.uuid);
    }, [folder, selectFolder]);

    const headerProps = {
        size: folder.size,
        name: folder.name,
        deletable,
        selected: folderIsSelected,
        expanded,
        onToggle: toggleFiles,
        onEdit: handleEdit,
        onDelete: handleDeleteFolder
    };

    return (
        <>
            {editedFolderId === folder.uuid ? (
                <FolderEditForm previousName={folder.name} onRenameFolder={commitChanges} />
            ) : (
                <FolderHeader {...headerProps} />
            )}
            <TransitionedFolderFiles
                in={expanded}
                folder={folder}
                selectedFileId={uuid}
                onAddFile={handleAddFile}
                onSelectFile={selectFile}
            />
        </>
    );
});

export default Folder;
