import React, { memo } from 'react';
import AppLayout from 'layouts/app-layout/AppLayout';
import AppWorkspace from 'layouts/app-workspace/AppWorkspace';
import Navigation from 'components/navigation/Navigation';
import Bricks from 'components/bricks/Bricks';
import FilesProvider from 'components/files-context/FilesProvider';
import { show, subscribe } from 'emitters/navigation-emitter';
import { startGoogleAnalytics } from 'components/google-analytics/google-analytics';
import SettingsProvider from 'components/settings-context/SettingsProvider';

startGoogleAnalytics();

const App = memo(() => {
    return (
        <AppLayout>
            <AppWorkspace>
                <SettingsProvider>
                    <FilesProvider>
                        <Navigation visibilitySubscriber={subscribe} />
                        <Bricks onShowNavigation={show} />
                    </FilesProvider>
                </SettingsProvider>
            </AppWorkspace>
        </AppLayout>
    );
});

export default App;
