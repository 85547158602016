/**
 * Excludes percent tokens (such as is, of) from expression in case they are irrelevant in the expression context
 * @param expr initial expression
 * @param tokens initially collected tokens
 * @returns initial tokens with exclusion of percent tokens
 */
export default function excludeInapplicablePercentTokens(expr, tokens) {
    let search = expr.search(/\W*(is)|(of)|(to)\W*/g);
    if (search === -1) return tokens;

    ////example: [20 is 10% of]
    const isofPattern = /\d+\s+is\s+\d+%\s+of/g;
    //examples: [10% of 1000] OR [10% of (4 + 5)]
    const ofPattern = /\d+%\s+of\s+(\d+|(\(\s*(\d+)\s*([+\-*/^]\s*\d+\s*)+\)))/g;

    //examples: [10 of 1000 to %]
    const percentOfNumberPattern = /\d+\s+of\s+\d+\s+to\s+%/g;

    if (expr.match(isofPattern) || expr.match(ofPattern) || expr.match(percentOfNumberPattern)) return tokens;

    const excludeTokens = ['is', 'of', 'to', '%'];
    return tokens.filter(token => !excludeTokens.includes(token.image));
}
