import React from 'react';
import { StyledFileTree } from 'components/file-tree/StyledFileTree';
import { DEFAULT, SHARED_WITH_ME } from 'constants/folder-types';
import { Splitter } from 'components/navigation/StyledNavigation';
import Folder from 'components/file-tree/Folder';

const FileTree = props => {
    const { fileTree, addFileAndSelect, selectFile, selectedFile, deleteFolder } = props;
    const fProps = { addFileAndSelect, selectFile, selectedFile, deleteFolder };
    return (
        <StyledFileTree>
            {fileTree
                .filter(folder => folder.type === SHARED_WITH_ME)
                .map(folder => (
                    <Folder key={folder.uuid} folder={folder} {...fProps} />
                ))}
            <Splitter />
            {fileTree
                .filter(folder => folder.type !== SHARED_WITH_ME)
                .map(folder => (
                    <Folder key={folder.uuid} folder={folder} deletable={folder.type !== DEFAULT} {...fProps} />
                ))}
        </StyledFileTree>
    );
};

export default FileTree;
