import React from 'react';
import ChatIcon from 'components/svg-icons/ChatIcon';
import FeedbackLinkLayout from 'components/feedback-link/feedback-link-styles';

const FeedbackLink = () => {
    return (
        <FeedbackLinkLayout target="_blank" rel="noopener noreferrer" href="https://shipright.community/getbricks">
            <span>Feedback</span>
            <ChatIcon />
        </FeedbackLinkLayout>
    );
};

export default FeedbackLink;
