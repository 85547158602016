import styled from 'styled-components/macro';
import { md, sm, xs } from 'constants/device-widths';
import { COLUMN_MODE } from 'components/settings-context/view-modes';

const StyledBricks = styled.div`
    position: relative;

    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    box-sizing: border-box;
    width: 792px;

    background-color: var(--editor-bg-color);

    @media (max-width: ${md}px) {
        & {
            width: calc(100% - 220px);
        }
    }

    @media (max-width: ${sm}px) {
        & {
            width: 100%;
        }
    }
`;

export const StyledBricksHeader = styled.div`
    z-index: 300;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-bottom: 0.5rem;

    background: ${({ viewMode }) =>
        viewMode === COLUMN_MODE
            ? 'linear-gradient(to right,#fafafa 0%,#fafafa 24px,#ffffff 24px,#ffffff 630px,rgba(0,0,0,0.12) 630px,rgba(0,0,0,0.12) 631px,#fafafa 631px,#fafafa 100%)'
            : 'linear-gradient(to right,#ffffff 0%,#ffffff 100%)'};

    @media (max-width: ${xs}px) {
        flex-direction: column;
        justify-content: initial;

        background: ${({ viewMode }) =>
            viewMode === COLUMN_MODE
                ? 'linear-gradient(to right,#fafafa 0%,#fafafa 24px,#ffffff 24px,#ffffff 70%,#fafafa 70%,#fafafa 100%)'
                : 'linear-gradient(to right,#ffffff 0%,#ffffff 100%)'};
    }
`;

export const NotepadContainer = styled.div`
    box-sizing: border-box;
    height: 100%;
    padding-right: ${({ viewMode }) => (viewMode === COLUMN_MODE ? '10rem' : 0)};
    padding-left: 1.5rem;
    overflow-y: auto;

    background: ${({ viewMode }) =>
        viewMode === COLUMN_MODE
            ? 'linear-gradient(to right,#fafafa 0%,#fafafa 24px,#ffffff 24px,#ffffff 630px,rgba(0,0,0,0.12) 630px,rgba(0,0,0,0.12) 631px,#fafafa 631px,#fafafa 100%)'
            : 'linear-gradient(to right,#ffffff 0%,#ffffff 100%)'};

    /*margin: 0.25rem 0.25rem 0.25rem 0;*/

    &::-webkit-scrollbar {
        width: 4px;

        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        width: 4px;

        border-radius: 8px;

        background: rgba(196, 196, 196, 0.5);
    }

    /* stylelint-disable*/
    @media (max-width: ${xs}px) {
        & {
            padding-right: ${({ viewMode }) => (viewMode === COLUMN_MODE ? '7rem' : 0)};
        }

        max-height: calc(100% - 93px);
        background: ${({ viewMode }) =>
            viewMode === COLUMN_MODE
                ? 'linear-gradient(to right,#fafafa 0%,#fafafa 24px,#ffffff 24px,#ffffff 70%,#fafafa 70%,#fafafa 100%)'
                : 'linear-gradient(to right,#ffffff 0%,#ffffff 100%)'};
    }
    /* stylelint-enable*/
`;

export default StyledBricks;
