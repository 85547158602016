import React, { memo, useEffect, useMemo, useRef } from 'react';
import { nanoid } from 'nanoid';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import buildPlugins from 'components/notepad/plugins/build-plugins';
import { schema } from 'components/notepad/schema';
import StyledNotepad from 'components/notepad/StyledNotepad';
import { actionTypes, subscribe } from 'emitters/app-emitter';
import { filterByType, getValueForSetting } from 'components/settings/settings-utils';
import { CALCULATOR } from 'constants/setting-types';
import applyDevTools from 'prosemirror-dev-tools';

const initState = {
    doc: { type: 'doc', content: [{ type: 'paragraph', attrs: { dataId: nanoid() } }] },
    selection: { type: 'text', anchor: 1, head: 1 }
};

const Notepad = memo(({ uuid, initialContent, onChange, notepadProps }) => {
    const containerEl = useRef();
    const viewField = useRef();
    const stateField = useRef();

    const { viewMode, settings } = notepadProps;
    const spellcheck = getValueForSetting('spellcheck', settings, false);

    const plugins = useMemo(
        () => buildPlugins({ schema, uuid, onChange, settings: filterByType(CALCULATOR, settings) }),
        [uuid, onChange, settings]
    );

    useEffect(() => {
        const state = { ...initState, ...initialContent };
        stateField.current = EditorState.fromJSON({ schema, plugins }, state);
    }, [plugins, initialContent]);

    useEffect(() => {
        const state = stateField.current;
        const view = new EditorView(containerEl.current, { state });

        //apply ProseMirror dev tool
        if (process.env.NODE_ENV !== 'production') applyDevTools(view);

        view.dom.spellcheck = spellcheck;
        viewField.current = view;
        return () => view.destroy();
    }, [spellcheck]);

    useEffect(() => {
        const view = viewField.current;
        const state = stateField.current;
        if (view.state !== state) view.updateState(state);
    }, [plugins, initialContent]);

    useEffect(() => {
        return subscribe(({ type }) => {
            switch (type) {
                case actionTypes.FOCUS: {
                    viewField.current.focus();
                    return;
                }
                default:
                    return;
            }
        });
    }, []);

    return <StyledNotepad ref={containerEl} viewMode={viewMode} />;
});

export default Notepad;
