import React from 'react';

import HelpIcon from 'components/svg-icons/HelpIcon';
import ReferenceLayout from 'components/reference-link/reference-link-styles';

const ReferenceLink = () => {
    return (
        <ReferenceLayout target="_blank" rel="noopener noreferrer" href="https://getbricks.app/docs/about">
            <span>Reference</span>
            <HelpIcon />
        </ReferenceLayout>
    );
};

export default ReferenceLink;
