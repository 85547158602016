/**
 * Updates each element from specified collection in database if specified predicate fulfils for a given element
 */
export const updateCollection = (collection, predicate, modification) => {
    return collection.map(el => (predicate(el) ? { ...el, ...modification } : el));
};

/**
 * Returns a predicates that checks that a given uuid is equal to the uuid of an object
 */
export const uuidEquals = uuid => {
    return obj => {
        return obj.uuid === uuid;
    };
};
