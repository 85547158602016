import createEventEmitter from 'utils/create-event-emitter';

const appEmitter = createEventEmitter();

export const actionTypes = {
    SAVE: 'SAVE',
    SHARE: 'SHARE',
    FOCUS: 'FOCUS',
    FOLDER_RENAMED: 'FOLDER_RENAMED',
    FOLDER_DELETED: 'FOLDER_DELETED',
    FOLDER_CREATED: 'FOLDER_CREATED',
    FILE_CREATED: 'FILE_CREATED',
    FILE_DELETED: 'FILE_DELETED',
    FILE_RENAMED: 'FILE_RENAMED',
    SHARED_FILE_OPENED: 'SHARED_FILE_CREATED'
};

export const subscribe = appEmitter.subscribe;

export const share = name => appEmitter.emit({ type: actionTypes.SHARE, name });

export const focus = () => appEmitter.emit({ type: actionTypes.FOCUS });

export const folderRenamed = name => appEmitter.emit({ type: actionTypes.FOLDER_RENAMED, name });

export const folderDeleted = () => appEmitter.emit({ type: actionTypes.FOLDER_DELETED });

export const folderCreated = () => appEmitter.emit({ type: actionTypes.FOLDER_CREATED });

export const fileCreated = () => appEmitter.emit({ type: actionTypes.FILE_CREATED });

export const sharedFileCreated = name => appEmitter.emit({ type: actionTypes.SHARED_FILE_OPENED, name });

export const fileDeleted = () => appEmitter.emit({ type: actionTypes.FILE_DELETED });

export const fileRenamed = name => appEmitter.emit({ type: actionTypes.FILE_RENAMED, name });
