import dataFile from 'examples/data/data-file.json';

export const populateExamples = async db => {
    const folderId = await db.folders.add({ name: 'Get Started', createdAt: Date.now() });

    let rows = dataFile.data.data;

    let files = rows.find(r => r.tableName === 'files');

    files.rows.forEach(file => {
        file.folderId = folderId;
        db.files.add(file);
    });

    let content = rows.find(r => r.tableName === 'fileContents');
    content.rows.forEach(row => {
        db.fileContents.put(row);
    });
};
