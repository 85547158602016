import React, { useContext, useMemo, useState } from 'react';
import FolderEditContext from 'components/folder-edit/FolderEditContext';
import FilesContext from 'components/files-context/FilesContext';

const FolderEditProvider = ({ children }) => {
    const [editedFolderId, setEditedFolderId] = useState();
    const { renameFolder } = useContext(FilesContext);

    const providerValue = useMemo(
        () => ({
            editedFolderId,
            selectFolder: setEditedFolderId,
            commitChanges: nextName => {
                renameFolder({ uuid: editedFolderId, name: nextName });
                setEditedFolderId(null);
            }
        }),
        [editedFolderId, renameFolder]
    );

    return <FolderEditContext.Provider value={providerValue}>{children}</FolderEditContext.Provider>;
};

export default FolderEditProvider;
