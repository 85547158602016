import { createContext } from 'react';

//see implementation in FileProvider
const FilesContext = createContext({
    inited: false,
    fileTree: [],
    selectedFile: null,
    addFile: () => {},
    addFolder: () => {},
    deleteFile: () => {},
    deleteFolder: () => {},
    renameFile: () => {},
    renameFolder: () => {},
    selectFile: () => {},
    addFileAndSelect: () => {},
    addSharedFileAndSelect: () => {}
});

export default FilesContext;
