import React from 'react';
import { NORMAL_MODE, ROW_MODE } from 'components/settings-context/view-modes';

export const SettingsContext = React.createContext({
    viewMode: ROW_MODE,
    settings: [],
    updateSettingValue: () => {},
    toggleViewMode: () => {},
    settingsMode: NORMAL_MODE,
    openSettings: () => {}
});
