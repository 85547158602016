import { MathLexer } from 'math/lexer';
import { parser } from 'math/parser';
import { createNumber } from './calculate/number-type';
import { createPercent } from './calculate/percent-type';
import { symbolToOperator } from 'math/calculate/operators';
import { calculate } from 'math/calculate/calculate';
import excludeInapplicablePercentTokens from './percent-token-utils';
import { getMath } from 'math/mathjs/mathjs';
import { getValueForSetting } from 'components/settings/settings-utils';

const BaseCstVisitor = parser.getBaseCstVisitorConstructor();

class MathInterpreter extends BaseCstVisitor {
    constructor(math) {
        super();
        this.math = math;
        // This helper will detect any missing or redundant methods on this visitor
        this.validateVisitor();
    }

    expression(ctx) {
        if (ctx.hasOwnProperty('whatPercentOfNumberExpression')) {
            return this.visit(ctx.whatPercentOfNumberExpression);
        }
        if (ctx.hasOwnProperty('percentOfExpression')) {
            return this.visit(ctx.percentOfExpression);
        }
        if (ctx.hasOwnProperty('additionExpression')) {
            return this.visit(ctx.additionExpression);
        }
        if (ctx.hasOwnProperty('whatNumberOfPercentExpression')) {
            return this.visit(ctx.whatNumberOfPercentExpression);
        }
    }

    additionExpression(ctx) {
        let result = this.visit(ctx.lhs);

        // "rhs" key may be undefined as the grammar defines it as optional (MANY === zero or more).
        if (ctx.rhs) {
            ctx.rhs.forEach((rhsOperand, idx) => {
                // there will be one operator for each rhs operand
                let rhsValue = this.visit(rhsOperand);
                let operator = ctx.AdditionOperator[idx];

                result = calculate(result, rhsValue, symbolToOperator[operator.image]);
            });
        }
        return result;
    }

    multiplicationExpression(ctx) {
        let result = this.visit(ctx.lhs);

        // "rhs" key may be undefined as the grammar defines it as optional (MANY === zero or more).
        if (ctx.rhs) {
            ctx.rhs.forEach((rhsOperand, idx) => {
                // there will be one operator for each rhs operand
                let rhsValue = this.visit(rhsOperand);
                let operator = ctx.MultiplicationOperator[idx];

                result = calculate(result, rhsValue, symbolToOperator[operator.image]);
            });
        }

        return result;
    }

    atomicExpression(ctx) {
        if (ctx.parenthesisExpression) {
            // passing an array to "this.visit" is equivalent
            // to passing the array's first element
            return this.visit(ctx.parenthesisExpression);
        } else if (ctx.unaryExpression) {
            return this.visit(ctx.unaryExpression);
        } else if (ctx.NumberLiteral) {
            return createNumber(this.math, ctx.NumberLiteral[0].image);
        } else if (ctx.PercentLiteral) {
            let val = ctx.PercentLiteral[0].image;
            return createPercent(this.math, val);
        }
    }

    parenthesisExpression(ctx) {
        // The ctx will also contain the parenthesis tokens, but we don't care about those
        // in the context of calculating the result.
        return this.visit(ctx.expression);
    }

    unaryExpression(ctx) {
        return this.visit(ctx.atomicExpression).negate();
    }

    powerExpression(ctx) {
        let base = this.visit(ctx.base);
        if (!ctx.exponent) return base;
        const arr = ctx.exponent;

        let reduced = arr.reduceRight((acc, cur) => {
            const val = this.visit(cur);
            return calculate(val, acc, symbolToOperator['^']);
        }, createNumber(this.math, 1));

        return calculate(base, reduced, symbolToOperator['^']);
    }

    whatNumberOfPercentExpression(ctx) {
        const percent = createPercent(this.math, ctx.PercentLiteral[0].image);
        const number = createNumber(this.math, ctx.NumberLiteral[0].image);
        return percent.risof(number);
    }

    percentOfExpression(ctx) {
        const percent = createPercent(this.math, ctx.PercentLiteral[0].image);
        const number = this.visit(ctx.atomicExpression);
        return percent.of(number);
    }

    /**
     * Gives the answer what is the percentage the first number takes from the second number
     * Expression: 10 of 1000 to %
     */
    whatPercentOfNumberExpression(ctx) {
        const firstNumber = createNumber(this.math, ctx.NumberLiteral[0].image);
        const secondNumber = createNumber(this.math, ctx.NumberLiteral[1].image);

        return createPercent(
            this.math,
            this.math.number(this.math.evaluate(`(${firstNumber} / ${secondNumber}) * 100`))
        );
    }
}

export const evaluate = (expr, math = getMath().getMathJs()) => {
    const interpreter = new MathInterpreter(math);
    const lexResult = MathLexer.tokenize(expr);
    parser.input = excludeInapplicablePercentTokens(expr, lexResult.tokens);

    const expression = parser.expression();

    const result = interpreter.visit(expression);
    return {
        result: result,
        lexerErrors: lexResult.errors,
        parserErrors: parser.errors
    };
};

function createMathJsConfig(settings) {
    let precision = getValueForSetting('precision', settings, 24);
    let round = getValueForSetting('round', settings, 5);

    return {
        number: 'BigNumber',
        precision: precision,
        round: round
    };
}

const prepareMjsResult = (valid, val, type, scope) => {
    return {
        result: {
            value: valid ? val : undefined,
            type: valid ? type : undefined,
            isValid: () => valid,
            scope: scope
        },
        lexerErrors: [],
        parserErrors: []
    };
};

export const tryEvaluateUsingMathJs = (expr, scope = {}, settings = []) => {
    const config = createMathJsConfig(settings);
    const math = getMath(config);
    const mathJs = math.getMathJs();
    const { round } = config;

    try {
        let res = math.eval(expr, scope);
        let valid = !!(res || res.result.result);
        if (valid && res.result.isUnit) {
            return prepareMjsResult(valid, res.result, 'unit');
        }
        if (valid && typeof res.result === 'boolean') {
            return prepareMjsResult(valid, res.result, 'bool');
        }
        if (valid && res.isBinary) {
            return prepareMjsResult(valid, res.result, 'binary');
        }

        //otherwise consider result as a number
        let val = mathJs.number(res.result);

        if (round && round > 0) {
            val = mathJs.round(val, round);
        }
        return prepareMjsResult(valid, val, 'number', scope);
    } catch (e) {
        //If we could not evaluate expression by using math-js, then try to evaluate with out custom evaluator
        let evaluated = evaluate(expr, mathJs);

        let valid = !!(evaluated.result && (evaluated.result.value || evaluated.result.value <= 0));

        //todo extract rounding into separate entity to handle it in one place
        let val = valid && evaluated.result.value;
        if (valid && round && round > 0) {
            val = mathJs.round(val, round);
        }
        return {
            ...evaluated,
            result: {
                value: valid ? val : undefined,
                type: valid ? evaluated.result.type : undefined,
                isValid: () => valid
            }
        };
    }
};
