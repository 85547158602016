import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FilesContext from 'components/files-context/FilesContext';
import * as BricksDb from 'db/db';
import * as dbChangeTypes from 'constants/db-change-types';
import { uniqueTabId } from 'db/db-change-hook';
import { emitChange } from 'emitters/file-title-emitter';
import {
    fileCreated,
    fileDeleted,
    fileRenamed,
    folderRenamed,
    folderCreated,
    folderDeleted,
    sharedFileCreated
} from 'emitters/app-emitter';
import { DEFAULT, SHARED_WITH_ME } from 'constants/folder-types';
import { DEFAULT_FILE_TO_OPEN_UUID } from 'constants/db-constants';
import { updateCollection, uuidEquals } from 'db/db-utils';
import { NORMAL_MODE } from 'components/settings-context/view-modes';
import { SettingsContext } from 'components/settings-context/SettingsContext';
import { getValueForSettingBySettingId } from 'components/settings/settings-utils';

const FilesProvider = ({ children }) => {
    const [inited, setInited] = useState(false);
    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);
    const [selectedFileId, updateSelectedFileId] = useState(localStorage.selectedFileId || null);
    const dbRef = useRef();
    const filesInitedRef = useRef(false);
    const foldersInitedRef = useRef(false);

    const { openSettings, settingsMode, settings } = useContext(SettingsContext);

    const showFolderSize = getValueForSettingBySettingId(4, settings, true);

    const defaultFileToOpenIsAvailable = useRef(false);

    useEffect(() => {
        const db = BricksDb.get();
        dbRef.current = db;

        db.folders
            .orderBy('createdAt')
            .toArray()
            .then(folders => {
                setFolders(folders);
                foldersInitedRef.current = true;
                if (filesInitedRef.current) setInited(true);
            });

        db.files
            .orderBy('createdAt')
            .toArray()
            .then(files => {
                setFiles(files);
                filesInitedRef.current = true;
                if (foldersInitedRef.current) setInited(true);
            });

        let defaultFiles = db.files.filter(file => file.uuid === DEFAULT_FILE_TO_OPEN_UUID);
        if (!defaultFileToOpenIsAvailable.current && defaultFiles.length > 0)
            defaultFileToOpenIsAvailable.current = true;

        db.on('changes', changes => {
            changes.forEach(change => {
                const changesMadeByCurrentTab =
                    change.source === uniqueTabId || (change.obj && change.obj.source === uniqueTabId);
                const filesOrFoldersTable = change.table === 'files' || change.table === 'folders';

                if (changesMadeByCurrentTab || !filesOrFoldersTable) return;

                switch (change.type) {
                    case dbChangeTypes.CREATED: {
                        if (change.table === 'files') {
                            setFiles(prevFiles => [...prevFiles, change.obj]);
                        } else {
                            setFolders(prevFolders => [...prevFolders, change.obj]);
                        }
                        break;
                    }
                    case dbChangeTypes.UPDATED: {
                        const { key, mods, table } = change;
                        if (table === 'files') {
                            emitChange(change.obj);
                            setFiles(prevFiles => updateCollection(prevFiles, uuidEquals(key), mods));
                        } else {
                            setFolders(prevFolders => updateCollection(prevFolders, uuidEquals(key), mods));
                        }
                        break;
                    }
                    case dbChangeTypes.DELETED: {
                        const { key, table } = change;
                        if (table === 'files') {
                            setFiles(prevFiles => prevFiles.filter(({ uuid }) => uuid !== key));
                            updateSelectedFileId(prev => (prev === key ? null : prev));
                        } else {
                            setFolders(prevFolders => prevFolders.filter(({ uuid }) => uuid !== key));
                        }
                        break;
                    }
                    default:
                }
            });
        });
    }, []);

    useEffect(() => {
        if (selectedFileId) {
            localStorage.selectedFileId = selectedFileId;
        } else if (defaultFileToOpenIsAvailable.current) {
            //open Welcoming file by default
            updateSelectedFileId(DEFAULT_FILE_TO_OPEN_UUID);
        } else if (filesInitedRef.current && files.length !== 0) {
            updateSelectedFileId(files[0].uuid);
        }
    }, [selectedFileId, files, folders]);

    /**
     * addFolder
     */
    const addFolder = useCallback(() => {
        const name = 'New Folder';
        dbRef.current.folders
            .add({ name, createdAt: Date.now() })
            .then(uuid => setFolders(prevFolders => [...prevFolders, { uuid, name }]));
        folderCreated();
    }, []);

    /**
     * renameFolder
     */
    const renameFolder = useCallback(({ uuid, name }) => {
        dbRef.current.folders
            .update(uuid, { name })
            .then(() => setFolders(prevFolders => updateCollection(prevFolders, uuidEquals(uuid), { name })));

        folderRenamed(name);
    }, []);

    /**
     * addFile
     */
    const addFile = useCallback(({ folderId }) => {
        dbRef.current.files
            .add({ folderId, createdAt: Date.now() })
            .then(uuid => setFiles(prevFiles => [...prevFiles, { uuid, folderId }]));
    }, []);
    /**
     * renameFile
     */
    const renameFile = useCallback(({ uuid, name }) => {
        dbRef.current.files
            .update(uuid, { name })
            .then(() => setFiles(prevFiles => updateCollection(prevFiles, uuidEquals(uuid), { name })));
        fileRenamed(name);
    }, []);

    const selectFile = useCallback(
        ({ uuid }) => {
            if (settingsMode !== NORMAL_MODE) {
                openSettings();
            }
            //setSettingsMode(NORMAL_MODE);
            //localStorage.settingsMode = NORMAL_MODE;
            updateSelectedFileId(uuid);
        },
        [openSettings, settingsMode]
    );

    const addFileAndSelect = useCallback(
        ({ folderId }) => {
            dbRef.current.files.add({ folderId, createdAt: Date.now() }).then(uuid => {
                selectFile({ uuid });
                setFiles(prevFiles => [...prevFiles, { uuid, folderId }]);
            });
            fileCreated();
        },
        [selectFile]
    );

    const addSharedFileAndSelect = useCallback(
        async ({ title, doc }) => {
            const sharedWithMeFolder = folders.find(({ type }) => type === SHARED_WITH_ME);
            if (!sharedWithMeFolder) return;

            const { uuid: folderId } = sharedWithMeFolder;
            const uuid = await dbRef.current.files.add({ folderId, name: title, createdAt: Date.now() });

            setFiles(prevFiles => [...prevFiles, { uuid, name: title, folderId }]);

            await dbRef.current.fileContents.put({ uuid, content: { doc } });

            selectFile({ uuid });
            sharedFileCreated(title);
        },
        [folders, selectFile]
    );
    /**
     * deleteFolder
     */
    const deleteFolder = useCallback(({ uuid }) => {
        dbRef.current.files
            .where('folderId')
            .equals(uuid)
            .delete()
            .then(() =>
                dbRef.current.folders
                    .where('uuid')
                    .equals(uuid)
                    .and(({ type }) => ![DEFAULT, SHARED_WITH_ME].includes(type))
                    .delete()
            )
            .then(() => {
                setFiles(prevFiles => prevFiles.filter(file => file.folderId !== uuid));
                setFolders(prevFolders =>
                    prevFolders.filter(
                        folder => [DEFAULT, SHARED_WITH_ME].includes(folder.type) || folder.uuid !== uuid
                    )
                );
            });
        folderDeleted();
    }, []);

    /**
     * deleteFile
     */
    const deleteFile = useCallback(
        ({ uuid }) => {
            if (files.length === 1) return;
            dbRef.current.files
                .where('uuid')
                .equals(uuid)
                .delete()
                .then(() => {
                    setFiles(prevFiles => prevFiles.filter(file => file.uuid !== uuid));
                    updateSelectedFileId(prev => (prev === uuid ? null : prev));
                });
            fileDeleted();
        },
        [files]
    );

    /**
     * Prepares file tree content to rendering
     */
    const fileTree = useMemo(() => {
        const foldersSizes = folders.reduce(function (map, folder) {
            map[folder.uuid] = files.filter(({ folderId }) => folderId === folder.uuid).length;
            return map;
        }, {});

        return folders.map(folder => ({
            ...folder,
            size: showFolderSize ? foldersSizes[folder.uuid] : undefined,
            files: files.filter(({ folderId }) => folderId === folder.uuid)
        }));
    }, [folders, files, showFolderSize]);

    const selectedFile = useMemo(() => {
        return files.find(({ uuid }) => uuid === selectedFileId);
    }, [files, selectedFileId]);

    const providerValue = useMemo(
        () => ({
            inited,
            fileTree,
            selectedFile,
            addFile,
            addFolder,
            deleteFile,
            deleteFolder,
            renameFile,
            renameFolder,
            selectFile,
            addFileAndSelect,
            addSharedFileAndSelect
        }),
        [
            inited,
            fileTree,
            selectedFile,
            addFile,
            addFolder,
            deleteFile,
            deleteFolder,
            renameFile,
            renameFolder,
            selectFile,
            addFileAndSelect,
            addSharedFileAndSelect
        ]
    );

    return <FilesContext.Provider value={providerValue}>{children}</FilesContext.Provider>;
};

export default FilesProvider;
