/**
 * Returns setting tah has exact name
 */
export const getValueForSetting = (name, settings, defaultValue = null) => {
    if (!name || settings === undefined || settings.length === 0) {
        return defaultValue;
    }
    let found = settings.find(s => {
        return s.name === name;
    });

    return found ? found.value : defaultValue;
};

/**
 * Returns setting that has exact id
 */
export const getValueForSettingBySettingId = (id, settings, defaultValue = null) => {
    if (!id || settings === undefined || settings.length === 0) {
        return defaultValue;
    }
    let found = settings.find(s => {
        return s.settingId === id;
    });

    return found ? found.value : defaultValue;
};

/**
 * Returns settings filtered by type
 */
export const filterByType = (type, settings) => {
    return settings.filter(s => s.type === type);
};
