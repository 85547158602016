/**
 * @param {math}  math  math js interpreter
 * @param {String}  str  number string literal
 * @return Number parsed number
 */
export const parseNumber = (math, str) => {
    if (typeof str === 'number') return str;
    let literal = str.split(' ').join('');
    let multiplier = 1;

    const foundK = literal.match(/\d+[Kk]$/g);
    if (foundK) {
        multiplier = 1000;
        literal = literal.trim().slice(0, literal.length - 1);
    }

    const foundM = literal.match(/\d+[Mm]$/g);
    if (foundM) {
        multiplier = 1000000;
        literal = literal.slice(0, literal.length - 1);
    }

    literal = literal.replace(',', '.');
    let number = parseFloat(literal);
    return math.number(math.evaluate(`${number} * ${multiplier}`));
};
