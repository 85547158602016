import dataFile from 'components/settings/default-settings.json';

export const populateDefaultSettings = async db => {
    let rows = dataFile.rows;

    rows.forEach(setting => {
        db.transaction('rw', db.settings, () => {
            db.settings.put(setting);
        });
    });
};

export const loadDefaultSettings = () => {
    return dataFile.rows;
};
