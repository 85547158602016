import { parseNumber } from 'math/number-literal-utils';
import { NumberType } from 'math/calculate/number-type';
import { PERCENT_TYPE } from 'math/calculate/calculation-types';
import { calcTypeExp } from 'math/calculate/type-utils';

class PercentType {
    constructor(math, value) {
        this.math = math;
        this.type = PERCENT_TYPE;
        this.value = value;
    }

    plus(other) {
        return calcTypeExp((l, r) => new PercentType(this.math, this.math.add(l, r))).onlyIfOtherIsPercent(this, other);
    }

    rplus(other) {
        return calcTypeExp((l, r) => {
            let result = this.math.evaluate(`${r}+${l}/100*${r}`);
            return new NumberType(this.math, this.math.number(result));
        }).onlyIfOtherIsNumber(this, other);
    }

    minus(other) {
        return calcTypeExp((l, r) => new PercentType(this.math, this.math.subtract(l, r))).onlyIfOtherIsPercent(
            this,
            other
        );
    }

    rminus(other) {
        return calcTypeExp(
            (l, r) => new NumberType(this.math, this.math.number(this.math.evaluate(`${r} - (${l} * ${r}) / 100`)))
        ).onlyIfOtherIsNumber(this, other);
    }

    multiply(other) {
        return (
            calcTypeExp(
                (l, r) => new NumberType(this.math, this.math.number(this.math.evaluate(`(${l} / 100) * ${r}`)))
            ).onlyIfOtherIsNumber(this, other) ||
            calcTypeExp(
                (l, r) => new NumberType(this.math, this.math.number(this.math.evaluate(`${l} / 100 * ${r}/100`)))
            ).onlyIfOtherIsPercent(this, other)
        );
    }

    rmultiply(other) {
        return calcTypeExp(
            (l, r) => new NumberType(this.math, this.math.number(this.math.evaluate(`(${l} / 100) * ${r}`)))
        ).onlyIfOtherIsNumber(this, other);
    }

    of(other) {
        return this.multiply(other);
    }

    risof(other) {
        return calcTypeExp(
            (l, r) => new NumberType(this.math, this.math.number(this.math.evaluate(`${r} / (${l}/100)`)))
        ).onlyIfOtherIsNumber(this, other);
    }

    toString() {
        return this.value + '%';
    }
}

export const createPercent = (math, val) => {
    if (typeof val === 'number') return new PercentType(math, parseNumber(math, val));
    return new PercentType(math, parseNumber(math, val.slice(0, val.length - 1)));
};
