import { Decoration, DecorationSet } from 'prosemirror-view';
import { Plugin } from 'prosemirror-state';

function decorate(doc, id) {
    let decos = [];
    doc.descendants((node, pos) => {
        if (node.attrs) {
            const { dataId } = node.attrs;

            if (dataId === id) {
                const endPos = pos + node.nodeSize;

                let deco = Decoration.node(pos, endPos, { class: 'reused-badge-highlighter' });
                decos.push(deco);
            }
        }
    });
    return decos[0];
}

/**
 * Highlighter for selected result.
 * It adds highlighter class on expression from which the result was obtained
 */
const reusedBadgeHighlightPlugin = new Plugin({
    props: {
        decorations(state) {
            const selection = state.selection;

            let decos = [];
            state.doc.nodesBetween(selection.from, selection.to, (node, position, parent) => {
                if (node.type.name === 'expression') {
                    let { dataId } = node.attrs;

                    let deco = decorate(state.doc, dataId);
                    if (deco) decos.push(deco);
                }
            });
            if (decos.length > 0) {
                return DecorationSet.create(state.doc, decos);
            } else return DecorationSet.empty;
        }
    }
});

export default reusedBadgeHighlightPlugin;
