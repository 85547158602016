import React, { useCallback, useState } from 'react';

const CheckInputItem = ({ setting, updateSettingValue }) => {
    let [value, setValue] = useState(setting.value);

    const change = useCallback(
        e => {
            let checked = e.target.checked;

            setValue(checked);
            updateSettingValue({ uuid: setting.uuid, value: checked });
        },
        [setting.uuid, updateSettingValue]
    );

    return <input id={setting.uuid} type="checkbox" checked={value} onChange={e => change(e)} />;
};

export default CheckInputItem;
