import React, { useCallback, useState } from 'react';

const NumberInputItem = ({ setting, updateSettingValue }) => {
    let [value, setValue] = useState(setting.value);

    const onChange = useCallback(
        e => {
            let number = parseInt(e.target.value);
            setValue(number);
            updateSettingValue({ uuid: setting.uuid, value: number });
        },
        [setting.uuid, updateSettingValue]
    );

    return <input id={setting.uuid} name={setting.name} type="number" value={value} onChange={e => onChange(e)} />;
};

export default NumberInputItem;
