import styled from 'styled-components/macro';
import { sm } from 'constants/device-widths';

/**
 * Defines main container for all setting panels: CalculatorSettings, EditorSettings, etc.
 */
export const StyledSettingsContainer = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    box-sizing: border-box;
    margin: 0;
    padding: 1rem 2rem;

    background-color: var(--editor-bg-color);

    @media (max-width: ${sm}px) {
        & {
            z-index: 300;

            align-items: stretch;
            width: 100%;
            max-height: calc(100% - 49px);
            padding: 1rem;
            overflow-y: auto;
        }
    }
`;

export const StyledSettingsItem = styled.div`
    padding-bottom: 1rem;

    &:not(:first-child) {
        padding: 1rem 0;

        border-top: solid 1px var(--splitter-border-color);
    }

    &:last-child {
        padding: 1rem 0 0;
    }

    p {
        margin: 0;

        color: var(--editor-main-color);
        font-size: 0.875rem;
        line-height: 1.5;
        white-space: pre-wrap;
    }

    li p {
        margin: 0.5rem 0 0 0;

        color: var(--nav-color);
    }
`;

/**
 * Defines list container that contains individual setting items
 * see also SettingHeader.js
 */
export const StyledSettingsPanel = styled.ul`
    margin: 0;
    padding: 0;
    overflow: hidden;

    transition: max-height 0.3s ease-in-out;
`;

/**
 * Setting item that contains value for a single parameter
 */
export const StyledSettingPanelItem = styled.li`
    padding: 0.25rem 0rem 0.25rem 0rem;
    overflow: hidden;

    list-style: none;

    -webkit-tap-highlight-color: transparent;

    & + li {
        padding-top: 1rem;
    }

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: ${sm}px) {
            & {
                max-width: initial;
            }
        }
    }

    label {
        padding-right: 1rem;

        color: var(--editor-main-color);
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1.5;

        & + p {
            margin: 0.3rem 0 0 0;

            color: rgba(0, 0, 0, 0.6);
        }
    }

    input[type='number'] {
        width: 35px;

        font-size: var(--toolbar-font-size);

        @media (max-width: ${sm}px) {
            & {
                font-size: var(--editor-main-font-size);
            }
        }
    }
`;
export const StyledSettingsHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    width: 792px;
    margin: 0;
    padding: 1rem 1.5rem 0 2rem;

    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    line-height: 1.25;

    @media (max-width: ${sm}px) {
        & {
            z-index: 300;

            flex-direction: column;
            align-items: stretch;
            width: 100%;
            padding: 0;
        }
    }
`;

export const TextAreaWrapperSettings = styled.div`
    color: var(--toolbar-color-activ);
    font-weight: 500;
    font-size: var(--title-font-size);

    @media (max-width: ${sm}px) {
        display: none;
    }
`;
