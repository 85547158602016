import isFunction from 'lodash/isFunction';
import { all, create } from 'mathjs';
// eslint-disable-next-line no-restricted-imports
import isEqual from 'lodash/isEqual';

const restrict = m => {
    m.import(
        {
            import: function () {
                throw new Error('Forbidden');
            },
            createUnit: function () {
                throw new Error('Forbidden');
            }
        },
        { override: true }
    );
};

const defaultConfig = {
    number: 'BigNumber',
    precision: 24
};

let currentConfig = null;
let math;
let binaryMath;

/**
 * returns wrapper over MathJs object
 */
export const getMath = (config = defaultConfig) => {
    if (math === null || !isEqual(currentConfig, config)) {
        currentConfig = config;

        math = create(all, config);
        restrict(math);

        binaryMath = create(all, { precision: currentConfig.precision });
        restrict(binaryMath);
    }
    return {
        eval: (expr, scope) => {
            let result;
            //todo refactor to more elegant and robust style
            //It turned out that we cannot use BigNumber in the config and use binary converter functions
            //So here I create usual math object without BigNumber in config
            if (
                expr.match(/bin\s*\(/g) ||
                expr.match(/hex\s*\(/g) ||
                expr.match(/oct\s*\(/g) ||
                expr.match(/number\s*\(/g) ||
                expr.match(/sqrt\s*\(/g)
            ) {
                result = binaryMath.evaluate(expr, scope);
                return { result, isBinary: true };
            } else {
                result = math.evaluate(expr, scope);
            }
            return isFunction(result) ? {} : { result };
        },
        getMathJs() {
            return math;
        }
    };
};
