export const COLUMN_MODE = 'COLUMN_MODE';
export const ROW_MODE = 'ROW_MODE';
/**
 * Represents usual working mode - user is working wit editors as usual (he is not in settings mode)
 */
export const NORMAL_MODE = 'NORMAL_MODE';
/**
 * Represents settings mode - user opened settings
 */
export const SETTINGS_MODE = 'SETTINGS_MODE';
