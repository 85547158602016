import Dexie from 'dexie';
import dexieObservable from 'dexie-observable';
import { addSourceToTransaction } from 'db/db-change-hook';
import * as folderTypes from 'constants/folder-types';
import { populateExamples } from 'examples/example-factory';
import { loadDefaultSettings, populateDefaultSettings } from 'components/settings/setting-factory';

const tables = ['files', 'folders', 'fileContents'];

const create = () => {
    const db = new Dexie('bricks', { addons: [dexieObservable] });

    db.version(3)
        .stores({
            folders: '$$uuid, createdAt, type',
            files: '$$uuid, folderId, createdAt',
            fileContents: 'uuid, modifiedAt',
            settings: '$$uuid, settingId, type, name, settingType'
        })
        .upgrade(trx => {
            let defaultSettings = loadDefaultSettings();
            let roundSetting = defaultSettings.find(s => s.settingId === 3);

            return trx.settings.put(roundSetting);
        })
        .upgrade(trx => {
            let defaultSettings = loadDefaultSettings();
            let showFolderSize = defaultSettings.find(s => s.settingId === 4);

            return trx.settings.put(showFolderSize);
        });

    db.on('populate', async () => {
        const folderId = await db.folders.add({ name: 'Notes', createdAt: Date.now(), type: folderTypes.DEFAULT });
        db.files.add({ folderId, createdAt: Date.now() });
        db.folders.add({ name: 'Shared With Me', createdAt: Date.now(), type: folderTypes.SHARED_WITH_ME });
        await populateExamples(db);
        await populateDefaultSettings(db);
    });

    tables.forEach(table => addSourceToTransaction(db[table]));

    db.open();

    return db;
};

let dbInstance = null;

export const get = () => {
    if (dbInstance === null) {
        dbInstance = create();
    }
    return dbInstance;
};
