import React from 'react';
import {
    ExpandButton,
    FolderControls,
    FolderLabel,
    StyledFolderHeaderInfo,
    StyledFolderHeader
} from 'components/file-tree/StyledFileTree';
import FileMenuButton from 'components/file-tree/FileMenuButton';
import ExpandMore from 'components/svg-icons/ExpandMore';
import OpenedFolder from 'components/svg-icons/OpenedFolder';
import ClosedFolder from 'components/svg-icons/ClosedFolder';

const FolderHeader = props => {
    const { name, size, deletable, selected, expanded, onToggle, onEdit, onDelete } = props;

    return (
        <StyledFolderHeader selected={selected}>
            {expanded ? <OpenedFolder /> : <ClosedFolder />}
            <FolderLabel onClick={onToggle}>{name}</FolderLabel>
            <FolderControls>
                {deletable && <FileMenuButton onEdit={onEdit} onDelete={onDelete} />}
                <ExpandButton title="Expand more" opened={expanded} onClick={onToggle}>
                    <ExpandMore />
                </ExpandButton>
            </FolderControls>
            {!expanded && <StyledFolderHeaderInfo>{size}</StyledFolderHeaderInfo>}
        </StyledFolderHeader>
    );
};

export default FolderHeader;
