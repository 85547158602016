/**
 * z-index for gutter with line numbers
 */
export const GUTTER_ZI = 0;

export const NOTEPAD_ZI = 100;

/**
 * z-index for navigation panel on small devices
 */
export const NAVIGATION_ZI = 999;
export const SNACK_BAR_ZI = 1500;

/**
 * z-index for file context menu, must be greater than `NAVIGATION_ZI`
 */
export const FILE_MENU_ZI = 1500;
