import React from 'react';
import styled from 'styled-components/macro';

const SvgIcon = styled.svg.attrs({ viewBox: '0 0 24 24', xmlns: 'http://www.w3.org/2000/svg' })`
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: 0.2em;

    font-size: 1.2em;

    user-select: none;
    fill: currentColor;
`;

export const createSvgIcon = path => {
    const Component = props => <SvgIcon {...props}>{path}</SvgIcon>;

    return React.memo(Component);
};
