import { createToken, Lexer } from 'chevrotain';

export const Identifier = createToken({ name: 'Identifier', pattern: /[a-zA-Z]\w+/, group: Lexer.SKIPPED });
export const EscapedNumberLiteral = createToken({
    name: 'EscapedNumberLiteral',
    pattern: /\\\d+/,
    group: Lexer.SKIPPED
});

export const AdditionOperator = createToken({ name: 'AdditionOperator', pattern: Lexer.NA });
export const Plus = createToken({ name: 'Plus', pattern: /\+/, categories: AdditionOperator });
export const Minus = createToken({ name: 'Minus', pattern: /-/, categories: AdditionOperator });

export const MultiplicationOperator = createToken({ name: 'MultiplicationOperator', pattern: Lexer.NA });
export const Multi = createToken({ name: 'Multi', pattern: /\*/, categories: MultiplicationOperator });
export const Div = createToken({ name: 'Div', pattern: /\//, categories: MultiplicationOperator });

export const PowerOperator = createToken({ name: 'PowerOperator', pattern: Lexer.NA });
export const Power = createToken({ name: 'Power', pattern: /\^/, categories: PowerOperator });

export const LParen = createToken({ name: 'LParen', pattern: /\(/ });
export const RParen = createToken({ name: 'RParen', pattern: /\)/ });
export const NumberLiteral = createToken({ name: 'NumberLiteral', pattern: /\d(( )?\d)*([.,]\d+)?([km](?!\w))?/i });
export const PercentLiteral = createToken({ name: 'PercentLiteral', pattern: /\d(( )?\d)*([.,]\d+)?\s*(%(?!\w))/i });
export const SolePercentLiteral = createToken({ name: 'SolePercentLiteral', pattern: /%/ });

//expressions
export const OfExpression = createToken({ name: 'OfExpression', longer_alt: Identifier, pattern: Lexer.NA });
export const OfToken = createToken({
    name: 'OfToken',
    pattern: /of/,
    longer_alt: Identifier,
    categories: OfExpression
});
export const IsToken = createToken({
    name: 'IsToken',
    pattern: /is/,
    longer_alt: Identifier,
    categories: OfExpression
});

export const ToToken = createToken({
    name: 'ToToken',
    pattern: /to/,
    longer_alt: Identifier,
    categories: OfExpression
});

// marking WhiteSpace as 'SKIPPED' makes the lexer skip it.
export const WhiteSpace = createToken({
    name: 'WhiteSpace',
    pattern: /\s+/,
    group: Lexer.SKIPPED
});

export const allTokens = [
    WhiteSpace, // whitespace is normally very common so it should be placed first to speed up the lexer's performance
    OfExpression,
    OfToken,
    IsToken,
    ToToken,
    Identifier, //meaningful tokens must go before any other keywords
    SolePercentLiteral,
    EscapedNumberLiteral,
    Plus,
    Minus,
    Multi,
    Div,
    LParen,
    RParen,
    PowerOperator,
    Power,
    PercentLiteral,
    NumberLiteral,
    AdditionOperator,
    MultiplicationOperator
];

export const MathLexer = new Lexer(allTokens);
