import { schema } from 'components/notepad/schema';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { Plugin } from 'prosemirror-state';
import splitLabel from 'components/notepad/plugins/calculation/split-label';
import { tryEvaluateUsingMathJs } from 'math/interpreter';

let calcSettings = null;
const isAndroid = /(android|bb\d+|meego).+mobile|Android/i.test(navigator.userAgent);

function preEval(doc) {
    const evaled = {};
    const scope = {};

    const expressions = [];

    const dataIdToLineNumber = {};

    let lineNumber = 0;
    doc.descendants(node => {
        if (node.type !== schema.nodes.paragraph) return;
        lineNumber++;
        const { dataId } = node.attrs;
        dataIdToLineNumber[dataId] = lineNumber;
    });

    // For each node in the document
    doc.descendants((node, pos) => {
        if (node.type !== schema.nodes.paragraph) return;

        const endPos = pos + node.content.size + 2;

        let text = '';
        node.descendants((node, nodePos) => {
            if (node.isText) {
                text += node.text;
            } else if (node.type === schema.nodes.expression) {
                const { dataId } = node.attrs;
                const { value, label } = evaled[dataId] || {};
                const textValue = value === undefined ? '##' : value;
                text += textValue;
                expressions.push({ value: ` ${textValue} `, dataId, position: pos + nodePos + 1, label });
            }
        });

        try {
            const [exp, label] = splitLabel(text);
            // eslint-disable-next-line no-eval
            const { result } = tryEvaluateUsingMathJs(exp, scope, calcSettings);

            if (typeof result === 'object' && result.isValid()) {
                //todo this is work-around - handle percent type. Need more robust approach to display different types!!!
                let val = result.type === 'percent' ? result.value + '%' : result.value;
                evaled[node.attrs.dataId] = { value: val, position: endPos, label };
            } else {
                evaled[node.attrs.dataId] = { value: '', position: endPos, label };
            }
        } catch (e) {}
    });

    return { evaled, expressions, dataIdToLineNumber };
}

function evalDecorator(doc) {
    const decos = [];
    const { evaled, expressions, dataIdToLineNumber } = preEval(doc);
    Object.keys(evaled).forEach(dataId => {
        const { value, position } = evaled[dataId];
        decos.push(Decoration.widget(position, valueBadge({ value, dataId, position }), { ignoreSelection: true }));
    });

    //handle expressions that were bound to reusable results
    expressions.forEach(({ dataId, position, value, label }) => {
        const lineNumber = dataIdToLineNumber[dataId];
        // By providing this spec object (with enabled ignoreSelection) the bug of expression badge deletion seems disappear (on  some android devices).
        // todo But needed a more clear and robust way to fix the bug though
        let item = Decoration.widget(position, expressionBadge({ value, label, dataId, position, lineNumber }), {
            side: 0,
            ignoreSelection: isAndroid
        });
        decos.push(item);
    });
    return DecorationSet.create(doc, decos);
}

function valueBadge({ value, dataId, position }) {
    const icon = document.createElement('div');
    icon.className = 'value-badge';
    icon.setAttribute('data-id', dataId);
    icon.meta = { dataId, position };
    icon.innerHTML = value;
    return icon;
}

function expressionBadge({ value, dataId, label, position, lineNumber }) {
    const icon = document.createElement('div');
    icon.className = 'expression-badge';
    icon.setAttribute('data-id', dataId);
    icon.meta = { dataId, position };
    if (label) icon.title = label;

    const lineNumberNode = document.createElement('span');
    lineNumberNode.appendChild(document.createTextNode(`L${lineNumber}`));
    lineNumberNode.classList.add('expression-badge-line');
    icon.appendChild(lineNumberNode);

    const valNode = document.createElement('span');
    valNode.appendChild(document.createTextNode(value));
    icon.appendChild(valNode);

    return icon;
}

const calculationPlugin = settings => {
    return new Plugin({
        state: {
            init(_, { doc }) {
                calcSettings = settings;

                return evalDecorator(doc);
            },
            apply(tr, old) {
                return tr.docChanged ? evalDecorator(tr.doc) : old;
            }
        },
        props: {
            decorations(state) {
                return this.getState(state);
            },
            handleClick(view, pos, event) {
                if (/value-badge/.test(event.target.className)) {
                    const { dataId, position } = event.target.meta;
                    const { dispatch, state } = view;
                    const { from } = state.selection;
                    if (position < from) {
                        dispatch(state.tr.replaceSelectionWith(schema.nodes.expression.create({ dataId })));
                    }
                    return true;
                }
            }
        }
    });
};

export default calculationPlugin;
