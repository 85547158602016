import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    NavigationContainer,
    NavigationFooter,
    NavigationHeader,
    SettingsContainer,
    StyledSettingsButton,
    TextButton
} from 'components/navigation/StyledNavigation';
import FileTree from 'components/file-tree/FileTree';
import FilesContext from 'components/files-context/FilesContext';
import * as visibilityTypes from 'components/navigation/navigation-visibility-types';
import Logo from 'components/logo/Logo';
import FolderEditProvider from 'components/folder-edit/FolderEditProvider';
import FeedbackLink from 'components/feedback-link/FeedbackLink';
import ReferenceLink from 'components/reference-link/ReferenceLink';
import SettingsIcon from 'components/svg-icons/SettingsIcon.js';
import { SettingsContext } from 'components/settings-context/SettingsContext';

const Navigation = ({ visibilitySubscriber }) => {
    const [visibility, setVisibility] = useState(visibilityTypes.NONE);
    const { fileTree, addFolder, addFileAndSelect, selectFile, deleteFolder, selectedFile } = useContext(FilesContext);
    const { openSettings } = useContext(SettingsContext);

    useEffect(() => {
        if (visibility === visibilityTypes.HIDDEN) {
            const resetVisibility = () =>
                setVisibility(prev => (prev === visibilityTypes.HIDDEN ? visibilityTypes.NONE : prev));
            setTimeout(resetVisibility, 300);
        }
    }, [visibility]);

    useEffect(() => {
        if (visibilitySubscriber) {
            return visibilitySubscriber(visibility => setVisibility(visibility));
        }
    }, [visibilitySubscriber]);

    const selectFileAndHide = useCallback(
        (...args) => {
            selectFile(...args);
            setVisibility(prev => (prev === visibilityTypes.VISIBLE ? visibilityTypes.HIDDEN : prev));
        },
        [selectFile]
    );

    const addFileAndSelectAndHide = useCallback(
        (...args) => {
            addFileAndSelect(...args);
            setVisibility(prev => (prev === visibilityTypes.VISIBLE ? visibilityTypes.HIDDEN : prev));
        },
        [addFileAndSelect]
    );

    const openSettingsAndShow = useCallback(() => {
        openSettings();
        setVisibility(prev => (prev === visibilityTypes.VISIBLE ? visibilityTypes.HIDDEN : prev));
    }, [openSettings]);

    const fileTreeProps = {
        fileTree,
        addFileAndSelect: addFileAndSelectAndHide,
        selectFile: selectFileAndHide,
        deleteFolder,
        selectedFile
    };

    return (
        <NavigationContainer visibility={visibility}>
            <NavigationHeader>
                <Logo />
                <span>Bricks</span>
            </NavigationHeader>

            <SettingsContainer>
                <FeedbackLink />
                <ReferenceLink />
                <StyledSettingsButton onClick={openSettingsAndShow}>
                    Settings
                    {<SettingsIcon />}
                </StyledSettingsButton>
            </SettingsContainer>

            <FolderEditProvider>
                <FileTree {...fileTreeProps} />
            </FolderEditProvider>
            <NavigationFooter>
                <TextButton onClick={addFolder}>Add folder...</TextButton>
            </NavigationFooter>
        </NavigationContainer>
    );
};

export default Navigation;
