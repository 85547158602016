import { CstParser } from 'chevrotain';
import {
    AdditionOperator,
    allTokens,
    IsToken,
    LParen,
    Minus,
    MultiplicationOperator,
    NumberLiteral,
    OfToken,
    PercentLiteral,
    PowerOperator,
    RParen,
    SolePercentLiteral,
    ToToken
} from 'math/lexer';

const defineRules = $ => {
    $.RULE('expression', () => {
        $.OR([
            {
                GATE: () => {
                    const firstTokFirstNumber = $.LA(1).tokenType;
                    const nextOfTokType = $.LA(2).tokenType;
                    const nextTokSecondNumber = $.LA(3).tokenType;
                    const nextToTokType = $.LA(4).tokenType;
                    const nextPercentTokType = $.LA(5).tokenType;
                    return (
                        firstTokFirstNumber === NumberLiteral &&
                        nextOfTokType === OfToken &&
                        nextTokSecondNumber === NumberLiteral &&
                        nextToTokType === ToToken &&
                        nextPercentTokType === SolePercentLiteral
                    );
                },
                ALT: () => $.SUBRULE($.whatPercentOfNumberExpression)
            },
            {
                GATE: () => {
                    const nextNumTokType = $.LA(1).tokenType;
                    const nextIsTokType = $.LA(2).tokenType;
                    const nextTokPercentLiteral = $.LA(3).tokenType;
                    const nextOfTokType = $.LA(4).tokenType;
                    return (
                        nextNumTokType === NumberLiteral &&
                        nextIsTokType === IsToken &&
                        nextTokPercentLiteral === PercentLiteral &&
                        nextOfTokType === OfToken
                    );
                },
                ALT: () => $.SUBRULE($.whatNumberOfPercentExpression)
            },
            {
                GATE: () => {
                    const nextTokType = $.LA(2).tokenType;
                    return nextTokType === OfToken;
                },
                ALT: () => $.SUBRULE($.percentOfExpression)
            },
            { ALT: () => $.SUBRULE($.additionExpression) }
        ]);
    });

    //  lowest precedence thus it is first in the rule chain
    // The precedence of binary expressions is determined by how far down the Parse Tree
    // The binary expression appears.
    $.RULE('additionExpression', () => {
        $.SUBRULE($.multiplicationExpression, { LABEL: 'lhs' });
        $.MANY(() => {
            // consuming 'AdditionOperator' will consume either Plus or Minus as they are subclasses of AdditionOperator
            $.CONSUME(AdditionOperator);
            //  the index "2" in SUBRULE2 is needed to identify the unique position in the grammar during runtime
            $.SUBRULE2($.multiplicationExpression, { LABEL: 'rhs' });
        });
    });

    $.RULE('percentOfExpression', () => {
        $.CONSUME(PercentLiteral);
        $.CONSUME(OfToken);
        $.SUBRULE($.atomicExpression);
    });

    //20 is 10% of
    $.RULE('whatNumberOfPercentExpression', () => {
        $.CONSUME1(NumberLiteral);
        $.CONSUME2(IsToken);
        $.CONSUME3(PercentLiteral);
        $.CONSUME4(OfToken);
    });

    //10 of 1000 to %
    $.RULE('whatPercentOfNumberExpression', () => {
        $.CONSUME1(NumberLiteral);
        $.CONSUME2(OfToken);
        $.CONSUME3(NumberLiteral);
        $.CONSUME4(ToToken);
        $.CONSUME5(SolePercentLiteral);
    });

    $.RULE('multiplicationExpression', () => {
        $.SUBRULE($.powerExpression, { LABEL: 'lhs' });

        $.MANY(() => {
            $.CONSUME(MultiplicationOperator);
            //  the index "2" in SUBRULE2 is needed to identify the unique position in the grammar during runtime
            $.SUBRULE2($.powerExpression, { LABEL: 'rhs' });
        });
    });

    $.RULE('powerExpression', () => {
        $.SUBRULE($.atomicExpression, { LABEL: 'base' });
        $.MANY(() => {
            $.CONSUME(PowerOperator);
            $.SUBRULE2($.atomicExpression, { LABEL: 'exponent' });
        });
    });

    $.RULE('atomicExpression', () =>
        $.OR([
            // parenthesisExpression has the highest precedence and thus it appears
            // in the "lowest" leaf in the expression ParseTree.
            { ALT: () => $.SUBRULE($.parenthesisExpression) },
            { ALT: () => $.SUBRULE($.unaryExpression) },
            { ALT: () => $.CONSUME(NumberLiteral) },
            { ALT: () => $.CONSUME(PercentLiteral) }
        ])
    );

    $.RULE('parenthesisExpression', () => {
        $.CONSUME(LParen);
        $.SUBRULE($.expression);
        $.CONSUME(RParen);
    });

    $.RULE('unaryExpression', () => {
        $.CONSUME(Minus);
        $.SUBRULE($.atomicExpression);
    });
};

export class MathParser extends CstParser {
    constructor() {
        super(allTokens);

        defineRules(this);

        this.performSelfAnalysis();
    }
}

export const parser = new MathParser([]);
