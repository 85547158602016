import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { NanoPop } from 'nanopop';
import { Transition } from 'react-transition-group';
import FileMenuContent from 'components/file-menu/FileMenuContent';

const portalEl = document.getElementById('fm-portal');
const timeouts = { enter: 0, exit: 300 };

const FileMenu = ({ opened, onClose, onEdit, onDelete, nodeRef }) => {
    const menuEl = useRef();
    const nanoPopRef = useRef();

    useEffect(() => {
        if (opened) {
            nanoPopRef.current = new NanoPop(nodeRef.current, menuEl.current);
            nanoPopRef.current.update();
        }
    });

    return (
        <Transition nodeRef={menuEl} in={opened} timeout={timeouts} unmountOnExit>
            {transition =>
                createPortal(
                    <FileMenuContent
                        ref={menuEl}
                        transition={transition}
                        onClose={onClose}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />,
                    portalEl
                )
            }
        </Transition>
    );
};

export default FileMenu;
