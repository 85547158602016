import { parseNumber } from 'math/number-literal-utils';
import { NUMBER_TYPE } from 'math/calculate/calculation-types';
import { calcTypeExp } from 'math/calculate/type-utils';

export class NumberType {
    constructor(math, value) {
        this.math = math;
        this.type = NUMBER_TYPE;
        this.value = value;
    }

    plus(other) {
        return calcTypeExp((l, r) => new NumberType(this.math, this.math.add(l, r))).onlyIfOtherIsNumber(this, other);
    }

    minus(other) {
        return calcTypeExp((l, r) => new NumberType(this.math, this.math.subtract(l, r))).onlyIfOtherIsNumber(
            this,
            other
        );
    }

    multiply(other) {
        return calcTypeExp((l, r) => new NumberType(this.math, this.math.multiply(l, r))).onlyIfOtherIsNumber(
            this,
            other
        );
    }

    divide(other) {
        return calcTypeExp((l, r) => new NumberType(this.math, this.math.divide(l, r))).onlyIfOtherIsNumber(
            this,
            other
        );
    }

    power(other) {
        return calcTypeExp((l, r) => new NumberType(this.math, this.math.pow(l, r))).onlyIfOtherIsNumber(this, other);
    }

    negate() {
        return new NumberType(this.math, this.math.unaryMinus(this.value));
    }

    toString() {
        return this.value.toString();
    }
}

export const createNumber = (math, val) => new NumberType(math, parseNumber(math, val));
