import React from 'react';
import { StyledSettingsPanel, StyledSettingsItem } from 'components/settings/StyledSettings';
import { useContext } from 'react';
import { SettingsContext } from 'components/settings-context/SettingsContext';
import SettingItem from 'components/settings/SettingItem';

const SettingsContainer = ({ title, typeFilter }) => {
    const { settings, updateSettingValue } = useContext(SettingsContext);

    return (
        <StyledSettingsItem>
            <p>
                <strong>{title}</strong>
            </p>
            <StyledSettingsPanel>
                {settings
                    .filter(setting => typeFilter(setting))
                    .map(setting => (
                        <SettingItem key={setting.uuid} setting={setting} updateSettingValue={updateSettingValue} />
                    ))}
            </StyledSettingsPanel>
        </StyledSettingsItem>
    );
};

export default SettingsContainer;
