import React from 'react';
import { StyledSettingsHeader, TextAreaWrapperSettings } from 'components/settings/StyledSettings';
import { GoToNavigation, GoToNavigationWrapper } from 'components/bricks-header/StyledBricksGoToNavHeader';
import ChevronLeft from 'components/svg-icons/ChevronLeft';

/**
 * Main header panel for all settings
 */
const SettingHeader = props => {
    const { onShowNavigation } = props;
    const { viewMode } = props;

    return (
        <StyledSettingsHeader viewMode={viewMode}>
            <GoToNavigationWrapper>
                <GoToNavigation onClick={onShowNavigation}>
                    <ChevronLeft />
                    <span>Settings</span>
                </GoToNavigation>
            </GoToNavigationWrapper>
            <TextAreaWrapperSettings viewMode={viewMode}>Settings</TextAreaWrapperSettings>
        </StyledSettingsHeader>
    );
};

export default SettingHeader;
